<template>
  <div class="ledger">
    <div class="header">
      <div class="body">
        <w3pp-slider>
          <h1 class="breadcrumb">
            <router-link :to="`/`">
              <icon class="home-icon" icon="homeIcon" height="20"/>
              Ledgers
            </router-link>
            <span>/</span>
            <div class="title">{{ ledgerRef?.data()?.title }}</div>
          </h1>
        </w3pp-slider>
      </div>
    </div>
    <div class="body">
      <w3pp-slider class="tabs">
        <router-link class="tab" :to="`/ledger/${$route.params.id}`">
          <icon icon="listIcon" height="20"/>
          Entries
        </router-link>
        <router-link class="tab" :to="`/ledger/${$route.params.id}/overview`">
          <icon icon="chartIcon" height="20"/>
          Overview
        </router-link>
        <router-link class="tab" :to="`/ledger/${$route.params.id}/settings`">
          <icon icon="cogIcon" height="20"/>
          Settings
        </router-link>
        <!-- <router-link class="tab" :to="`/ledger/${$route.params.id}/list`">Shopping List</router-link> -->
      </w3pp-slider>
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component
            :is="Component"
            :key="$route.fullPath"
            class="page"
          />
        </keep-alive>
      </router-view> 
    </div>
  </div>
</template>
<script>
import w3ppSlider from '@/components/w3ppSlider'
import homeIcon from '@iconify-icons/mdi/home'
import listIcon from '@iconify-icons/mdi/format-list-bulleted'
import chartIcon from '@iconify-icons/mdi/chart-line'
import cogIcon from '@iconify-icons/mdi/cog-outline'
import { Icon, addIcon } from '@iconify/vue'
addIcon('homeIcon', homeIcon)
addIcon('listIcon', listIcon)
addIcon('chartIcon', chartIcon)
addIcon('cogIcon', cogIcon)
export default {
  name: 'Ledger',
  components: {
    w3ppSlider,
    Icon
  },
  data () {
    return {
      entries: [],
      entryActions: null
    }
  },
  computed: {
    ledgerRef () {
      return this.$feed.data.ledgers?.[this.$route.params.id]
    }
  }
}
</script>
<style lang="scss" scoped>
  .body {
    margin: auto;
    max-width: 1200px;
  }
  .header {
    padding: 24px 16px;
    @media (min-width: 720px) {
      padding: 24px;
    }
    .breadcrumb {
      font-size: 16px;
      line-height: 30px;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 4px;
      .home-icon {
        margin-right: 4px;
      }
      a {
        color: #0366d6;
        text-decoration: none;
        display: flex;
        align-items: center;
        &:hover {
          text-decoration: underline;
        }
      }
      .title {
        font-weight: 600;
      }
    }
  }
  .tabs {
    padding: 0 8px;
    @media (min-width: 720px) {
      padding: 0 24px;
    }
    & :deep(.container):after {
      content: '';
      flex-shrink: 0;
      width: 16px;
      height: 1px;
    }
    
    .tab {
      color: inherit;
      text-decoration: none;
      border: 2px solid transparent;
      border-top-width: 1px;
      border-left-width: 1px;
      border-right-width: 1px;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 4px;
      height: 48px;
      font-weight: 500;
      padding: 0 8px;
      svg {
        color: #959da5;
      }
      &:hover {
        border-bottom-color: #d1d5da;
      }
      &.router-link-exact-active {

        // border-color: rgba(100, 121, 143, .12);
        border-bottom-color: #0366d6;
        // background: #f9fafa;

        &, svg {
          color: #0366d6;
        }
      }
    }
  }
  
  .page {
    box-shadow: 0 0 2px 0 rgba(100, 121, 143, .2);
  }
</style>
